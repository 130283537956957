<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="试卷基本信息"
      @head-save="headSave"
      @head-save-back="headSave(true)"
      @head-cancel="headCancel"
      @generate-paper="generatePaper"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>

    <div class="questionStaics" v-if="dataForm.id">
      <div class="q-item" v-for="item in questionTypeArr" :key="item.dictKey">
        <div class="q-label">{{ item.dictValue }}：</div> <div class="q-value">{{ questionTypeMapValue[item.dictKey] || 0 }}个</div> 
      </div>
    </div>
    <head-layout
      :head-btn-options="tableHeadOption"
      head-title="试题"
      @head-remove="headRemove"
      @head-generate-topic="openGenerateTopicDialog"
      @head-select-topic="openSelectTopicsDialog"
    ></head-layout>
    <grid-layout
      ref="gridLayTableTopic"
      :grid-row-btn="gridRowBtn"
      :table-options="tableTopicOptions"
      :table-data="pageData"
      :table-loading="tableLoading"
      @page-current-change="tableOnLoad"
      @page-size-change="tableOnLoad"
      @page-refresh-change="tableOnLoad"
      @row-remove="rowRemove"
    >
    </grid-layout>
    <div class="bottomPagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
    </div>
    <el-dialog
      v-dialog-drag
      title="选择试题"
      :modal="true"
      :modal-append-to-body="false"
      :visible.sync="selectTopicsDialog"
      width="80%"
      class="paperDialog qmDialog"
      @close="closeDialog"
    >
      <el-container>
        <CommonTree
          :titleShow="false"
          :treeData="examinationTreeData"
          :defaultProps="examinationDefaultProps"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          @getNodeClick="treeNodeClick"
        />
        <el-main>
          <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <div class="selectionListTips" v-if="selectionList.length">已选择{{selectionList.length}}题</div>
          <grid-layout
            ref="gridLayOut"
            :table-options="topicOptions"
            :table-data="topicData"
            :table-loading="tableLoading"
            :data-total="paperPage.total"
            :page="paperPage"
            @page-current-change="topicOnLoad"
            @page-size-change="topicOnLoad"
            @page-refresh-change="topicOnLoad"
            @gird-handle-select-click="selectionChange"
          ></grid-layout>
        </el-main>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="生成试题"
      :modal="true"
      :modal-append-to-body="false"
      :visible.sync="generateTopicsDialog"
      width="80%"
      class="paperDialog qmDialog"
      @close="generateTopicCloseDialog"
    >
      <el-container v-if="generateTopicsDialog">
        <el-main>
          <head-layout
            :head-btn-options="generateTopicsHeadOption"
            head-title="试题类型"
            @head-remove="headRemove"
            @head-add-tabs="headAdd"
          ></head-layout>
          <grid-layout
            ref="gridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            @row-remove="rowRemove"
          >
          </grid-layout>
        </el-main>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="generateTopicCloseDialog">取消</el-button>
        <el-button type="primary" @click="generateTopicConfirm">生成试题</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {getEduTree as eduTree} from "@/api/training/edutree";
import {getEduTree} from "@/api/training/examinationPaper";
import {getLineList, removeLine} from "@/api/training/edupapertype";
import {
  getDetail,
  update,
  checkNameExist,
  generatePaper,
  topicPage,
  saveOrUpdate,
  removeByEduPaperLine,
  groupStatistics,
  generateTopic
} from "@/api/training/edupaper";
import {listAll} from "@/api/training/eduquestions";
import CommonTree from "@/views/components/com_tree";
import {getList as getTopicList} from "@/api/training/eduquestions";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {dictionaryBiz} from "@/api/reportTasks";

let screenData = [];
let theAmountOfQuestions = 0
export default {
  name: "edupaperEdit",
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
  },
  data() {
    return {
      questionTypeArr:[],
      questionTypeMapValue:{},
      // 自动生成试题弹框
      generateTopicsDialog: false,
      // 试题选择弹框
      selectTopicsDialog: false,
      examinationTreeData: [],
      examinationDefaultProps: {
        label: "title",
        value: "id",
        key: "id"
      },
      searchTitle: "title",
      paperPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      topicData: [],
      tableTopicData: [],
      selectTopicCodes: [],
      pageData: [],
      dataForm: {},
      tableData: [],
      saveLoading: false,
      tableLoading: false,
      query: {},
      type: undefined,
      testPaperType: "",
      subjectType: "",
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList:[]
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.edupaper_add, true),
        viewBtn: this.vaildData(this.permission.edupaper_view, true),
        delBtn: this.vaildData(this.permission.edupaper_delete, true),
        editBtn: this.vaildData(this.permission.edupaper_edit, true)
      };
    },
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: 'save',
          loading: this.saveLoading,
        },
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-save-back",
          type: "button",
          icon: "",
          btnOptType: 'saveBack',
          loading: this.saveLoading,
        },
        // {
        //   label: '生成试卷',
        //   emit: "generate-paper",
        //   type: "button",
        //   icon: "",
        //   loading: this.saveLoading,
        // },
        {
          label: this.$t('cip.cmn.btn.cancel'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
          loading: this.saveLoading,
        }
      ]
    },
    formColumn() {
      return [
        {
          label: "试卷编号",
          prop: "code",
          slot: true,
          search: true,
          sortable: true,
          readonly: true,
          width: 180,
          placeholder: "保存后自动生成",
          rules: [{
            required: false,
            message: "请输入试卷编号",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "试卷名称",
          prop: "paperName",
          search: true,
          sortable: true,
          maxlength: 100,
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入试卷名称",
            trigger: ["blur", "change"]
          }]
        },
        // {
        //   label: "考试级别",
        //   prop: "lexaminationLevel",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   rules: [{
        //     required: true,
        //     message: "请输入考试级别",
        //     trigger: ["blur", "change"]
        //   }]
        // },
        // {
        //   label: "出题依据",
        //   prop: "accordingTo",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   rules: [{
        //     required: true,
        //     message: "请输入出题依据",
        //     trigger: ["blur", "change"]
        //   }]
        // },
        {
          label: "专业分类",
          prop: "subjectType",
          sortable: true,
          dicData: [],
          type: "tree",
          props: {
            label: "title",
            value: "value",
          },
          rules: [{
            required: true,
            message: "请输入分类",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "答题时长(分钟)",
          prop: "answerTime",
          type: "number",
          labelWidth: 135,
          sortable: true,
          rules: [{
            required: true,
            message: "请输入答题时长",
            trigger: ["blur", "change"]
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: "请输入正整数",
          }]
        },
        {
          label: "合格分",
          prop: "qualifiedScore",
          type: "number",
          sortable: true,
          hide: true,
          rules: [{
            required: true,
            message: "请输入合格分",
            trigger: ["blur", "change"]
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: "请输入正整数",
          }]
        },
        {
          label: "总分值",
          prop: "totalScore",
          readonly: true,
          hide: true,
          sortable: true,
          rules: [
            // {
            //   required: true,
            //   message: "请输入总分值",
            //   trigger: "change"
            // },
            {
              pattern: /^[0-9]*[0-9]*$/,
              message: "请输入正整数",
            }]
        },
        {
          label: "是否发布",
          prop: "paperStatus",
          sortable: true,
          type: 'select',
          dicUrl: '/api/sinoma-system/dict/dictionary?code=yes_no',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
          value: '0',
          rules: [{
            required: true,
            message: "请输入试卷状态",
            trigger: ["blur", "change"]
          }]
        },
        // {
        //   label: "是否公布答案",
        //   prop: "openAnswer",
        //   sortable: true,
        //   type: 'radio',
        //   labelWidth: 130,
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=YES_NO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   value: 'n',
        //   rules: [{
        //     required: true,
        //     message: "请输入是否公布答案",
        //     trigger: ["blur", "change"]
        //   }]
        // },
        {
          label: "试卷说明",
          prop: "paperExplain",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 100,
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入试卷说明",
            trigger: ["blur", "change"]
          }]
        },
      ]
    },
    tableHeadOption() {
      return [
        {
          label: "自动生成",
          emit: "head-generate-topic",
          type: "button",
          icon: "",
        },
        {
          label: "手动选择",
          emit: "head-select-topic",
          type: "button",
          icon: "",
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    generateTopicsHeadOption() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    tableOptions() {
      return {
        selection: false,
        menu: true,
        column: [
          {
            label: "试题类型",
            prop: "topicType",
            cell: true,
            sortable: true,
            type: 'select',
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_question_type',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: [{
              required: true,
              message: "请输入试题类型",
              trigger: ["blur", "change"]
            }],
            change: (res, r, a) => {
              var screenDataList = screenData.filter(e => {
                return (res.row.facilityType != "" ? e.facilityType == res.row.facilityType : true)
                  && (res.row.subjectType != "" ? e.subjectType == res.row.subjectType : true)
                  && (res.row.topicType != "" ? e.topicType == res.row.topicType : true)
              });
              theAmountOfQuestions += (theAmountOfQuestions = screenDataList.length)
              res.row.theAmountOfQuestions = theAmountOfQuestions
              theAmountOfQuestions = 0
            },
          },
          {
            label: "分类",
            prop: "subjectType",
            cell: true,
            sortable: true,
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            rules: [{
              required: true,
              message: "请输入分类",
              trigger: ["blur", "change"]
            }],
            change: (res, r, a) => {
              var screenDataList = screenData.filter(e => {
                return (res.row.facilityType != "" ? e.facilityType == res.row.facilityType : true)
                  && (res.row.subjectType != "" ? e.subjectType == res.row.subjectType : true)
                  && (res.row.topicType != "" ? e.topicType == res.row.topicType : true)
              });
              theAmountOfQuestions += (theAmountOfQuestions = screenDataList.length)
              res.row.theAmountOfQuestions = theAmountOfQuestions
              theAmountOfQuestions = 0
            },
          },
          {
            label: "难易度",
            prop: "facilityType",
            cell: true,
            sortable: true,
            type: "select",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_difficult_level',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: [{
              required: true,
              message: "请输入难易度",
              trigger: ["blur", "change"]
            }],
            change: (res, r, a) => {
              console.log("res.row.facilityType", res.row.facilityType)
              console.log("res.row.subjectType", res.row.subjectType)
              console.log("res.row.topicType", res.row.topicType)
              var screenDataList = screenData.filter(e => {
                return (res.row.facilityType != "" ? e.facilityType == res.row.facilityType : true)
                  && (res.row.subjectType != "" ? e.subjectType == res.row.subjectType : true)
                  && (res.row.topicType != "" ? e.topicType == res.row.topicType : true)
              });
              theAmountOfQuestions += (theAmountOfQuestions = screenDataList.length)
              res.row.theAmountOfQuestions = theAmountOfQuestions
              theAmountOfQuestions = 0
            },
          },
          {
            label: "数量",
            prop: "number",
            type: "number",
            cell: true,
            sortable: true,
            change: () => {
              this.calculate(null);
            },
            rules: [{
              required: true,
              message: "请输入数量",
              trigger: ["blur", "change"]
            }, {
              pattern: /^\d{1,3}$/,
              message: "请输入三位以内的正整数",
            }]
          },
          {
            label: "单题分值",
            prop: "score",
            type: "number",
            cell: true,
            sortable: true,
            change: () => {
              this.calculate(null);
            },
            rules: [{
              required: true,
              message: "请输入单题分值",
              trigger: ["blur", "change"]
            }, {
              pattern: /^\d{1,3}$/,
              message: "请输入三位以内的正整数",
            }]
          },
          {
            label: "题库数量",
            prop: "theAmountOfQuestions",
            type: "number",
            disabled: true,
          },
        ],
      }
    },
    tableTopicOptions() {
      return {
        selection: true,
        menu: true,
        column: [
          {
            label: "试卷编号",
            prop: "paperId",
            cell: false,
            sortable: true,
            type: 'select',
            hide: true,
          },
          {
            label: "试题编号",
            prop: "topicCode",
            cell: false,
            sortable: true,
            type: 'select',
            width: 200,
            rules: [{
              required: true,
              message: "请输入试题类型",
              trigger: ["blur", "change"]
            }]
          },
          {
            label: "试题名称",
            prop: "topicName",
            cell: false,
            sortable: true,
          },
          {
            label: "题目类型",
            prop: "topicType",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_question_type',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 100,
            overHidden: true,
            align: 'center',
          },
          {
            label: "分值",
            prop: "score",
            type: "number",
            cell: true,
            sortable: true,
            width: 125,
            change: (row) => {
              this.calculate(row);
            },
            rules: [{
              required: true,
              message: "请输入单题分值",
              trigger: ["blur", "change"]
            }, {
              pattern: /^(?:[1-9]\d{0,2}|0)$/,
              message: "请输入三位以内的正整数",
            }]
          },
        ],
      }
    },
    topicOptions() {
      return {
        linklabel: "code",
        index: true,
        indexLabel: "序号",
        menu: false,
        selectable: (row, index) => {
          return !this.selectTopicCodes.includes(row.id);
        },
        column: [
          {
            label: "编号",
            prop: "code",
            width: 180,
            align: 'left',
            overHidden: true,

          },
          {
            label: "分类",
            prop: "subjectType",
            type: 'tree',
            dicData: [],
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'number',
            overHidden: true,
            align: 'center',
            width: 100,
          },
          {
            label: "题目名称",
            prop: "subjectName",
            align: 'left',
            overHidden: true,
          },
          // {
          //   label: "出题依据",
          //   prop: "accordingTo",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
          //   align: 'center',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   overHidden: true,
          //   width: 150,
          // },
          // {
          //   label: "考试级别",
          //   prop: "lexaminationLevel",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 100,
          //   overHidden: true,
          //   align: 'center',
          // },
          {
            label: "难易度",
            prop: "facilityType",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_difficult_level',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 80,
            overHidden: true,
            align: 'center',
          },
          {
            label: "题目类型",
            prop: "topicType",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_question_type',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 100,
            overHidden: true,
            align: 'center',
          },
        ]
      }
    },
    gridRowBtn() {
      return [
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        }
      ]
    },
    searchColumns() {
      return [
        {
          prop: "subjectName",
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "题目名称",
          span: 3,
        },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "出题依据",
        //   prop: "accordingTo",
        //   search: true,
        //   type: 'select',
        //   span: 3,
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "考试级别",
        //   prop: "lexaminationLevel",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   span: 3,
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "难易度",
          prop: "facilityType",
          type: 'select',
          span: 3,
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_difficult_level',
          dicData: [],
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "题目类型",
          prop: "topicType",
          type: 'select',
          span: 3,
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_question_type',
          dicData: [],
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
        },
      ]
    },
  },
  methods: {
    getdictionaryBiz(){
      dictionaryBiz('exam_question_type').then(res=>{
        this.questionTypeArr = res.data.data || []
      })
    },
    generateTopicConfirm() {
      this.generatePaper();
    },
    openGenerateTopicDialog() {
      if (!this.dataForm.id) {
        this.$message.warning("请保存试卷基本信息后在进行此操作！");
      } else {
        this.generateTopicsDialog = true;
      }
    },
    generateTopicCloseDialog() {
      this.selectionList = [];
      this.paperPage.currentPage = 1;
      this.generateTopicsDialog = false;
    },
    confirm() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择试题");
        return;
      }
      this.selectTopicCodes = []
      this.selectionList.forEach((e) => {
        let topic = {}
        topic.questionId = e.id
        topic.topicType = e.topicType
        topic.topicName = e.subjectName
        topic.topicCode = e.code
        topic.score = 10
        this.tableTopicData.push(topic)
      })
      this.tableTopicData.forEach((item) => {
        this.selectTopicCodes.push(item.questionId)
      })
      this.closeDialog();
      for (let i = 0; i < this.tableTopicData.length; i++) {
        this.tableTopicData[i].$cellEdit = true;
      }
      let form = this.$refs.formLayout.dataForm;
      form.eduPaperTopicList = this.tableTopicData;
      saveOrUpdate(form).then(
        (res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.tableOnLoad(this.page);
        })
    },
    initTree() {
      eduTree()
        .then(res => {
          const {data} = res.data
          this.examinationTreeData = data;
          const column = this.findObject(this.topicOptions.column, 'subjectType')
          column.dicData = res.data.data
        })
    },
    openSelectTopicsDialog() {
      if (!this.dataForm.id) {
        this.$message.warning("请保存试卷基本信息后在进行此操作！");
      } else {
        this.selectTopicsDialog = true;
        this.initTree();
        this.topicOnLoad(this.paperPage);
      }
    },
    selectionChange(list) {
      this.selectionList = list
    },
    groupStatistics(){
      groupStatistics(this.dataForm.id).then(res=>{
        this.questionTypeMapValue = res.data.data || {}
        // console.log(res.data.data,'groupStatistics')
      })
    },
    gridHeadEmpty(query) {
      this.topicOnLoad(this.paperPage, query)
    },
    gridHeadSearch(query) {
      this.paperPage.currentPage = 1;
      this.topicOnLoad(this.paperPage, query)
    },
    treeNodeClick(node) {
      let params = {
        subjectType: node.id
      }
      this.paperPage.currentPage = 1
      this.subjectType = node.id
      this.topicOnLoad(this.paperPage, params)
    },
    closeDialog() {
      this.selectionList = [];
      this.paperPage.currentPage = 1;
      this.selectTopicsDialog = false;
    },
    initData() {
      getEduTree().then(res => {
        const column = this.findObject(this.$refs.formLayout.option.column, 'subjectType')
        column.dicData = res.data.data
        if (this.type == "add") {
          let node = this.$route.query.node;
          this.$refs.formLayout.dataForm.subjectType = node.id
        }
        // const column3 = this.findObject(this.tableTopicOptions.column, 'topicType')
        // column3.dicData = res.data.data
      })
      eduTree().then(res => {
        // const column = this.findObject(this.$refs.formLayout.option.column, 'subjectType')
        // column.dicData = res.data.data

        const column2 = this.findObject(this.tableOptions.column, 'subjectType')
        column2.dicData = res.data.data
        if (this.type == "add") {
          let node = this.$route.query.node;
          this.$refs.formLayout.dataForm.subjectType = node.id
        }
        // const column3 = this.findObject(this.tableTopicOptions.column, 'topicType')
        // column3.dicData = res.data.data
      })
    },
    async headSave(clo = false) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          let index = 0;
          let indexList = [];
          this.tableTopicData.forEach((e) => {
            ++index
            if (!e.score) {
              indexList.push(index)
              console.log(index, "index")
            }
          })
          if (indexList.length !== 0) {
            this.$message({
              type: "warning",
              message: '有试题行数据的分值项数据为空或0请检查！',
            });
            this.$refs.formLayout.$refs.form.allDisabled = false
            return;
          }

          let form = this.$refs.formLayout.dataForm;
          if (form.totalScore > 200) {
            this.$message({
              type: "warning",
              message: "总分值不能大于200!",
            });
            this.$refs.formLayout.$refs.form.clearValidate();
            return;
          }
          if (form.id && form.qualifiedScore > form.totalScore) {
            this.$message({
              type: "warning",
              message: "合格分不能大于总分值!",
            });
            this.$refs.formLayout.$refs.form.allDisabled = false
            this.$refs.formLayout.$refs.form.clearValidate();
            return;
          }
          form.eduPaperTopicList = this.tableTopicData;
          this.saveLoading = true
          this.tableLoading = true
          this.$loading();
          checkNameExist(form.paperName, form.id).then(
            (res) => {
              if (res.data == false) {
                this.flag = false;
                this.saveLoading = false
                this.tableLoading = false
                this.$refs.formLayout.$refs.form.allDisabled = false
                this.$message({
                  type: "warning",
                  message: "试卷名称不能重复!",
                });
              } else {
                this.saveLoading = true
                form.testPaperType = this.testPaperType
                saveOrUpdate(form).then(
                  (res) => {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                    });
                    this.dataForm = this.$refs.formLayout.dataForm = res.data.data;
                    if (clo) {
                      this.headCancel();
                    }
                    this.saveLoading = false;
                    this.tableLoading = false
                  }
                ).finally(() => {
                  this.$refs.formLayout.$refs.form.allDisabled = false
                });
              }
            }
          ).catch(() => {
            this.$refs.formLayout.$refs.form.allDisabled = false
          });
          this.$loading().close();
        }
      });
    },
    isRepeat(list = []) {
      let temp = []
      for (let i = 0; i < list.length; i++) {
        if (temp.indexOf(list[i]) === -1) {
          temp.push(list[i]);
        } else {
          return true
        }
      }
      return false
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    generatePaper() {
      this.$loading();
      // this.checkForm(() => {
      let form = this.$refs.formLayout.dataForm
      form.eduPaperTypeList = this.tableData;
      this.saveLoading = true
      this.tableLoading = true
      this.saveLoading = true
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].$cellEdit = true;
      }
      form.eduPaperTypeList = this.tableData;
      generateTopic(form).then(
        (res) => {
          this.tableData = res.data.data.eduPaperTypeList;
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.generateTopicsDialog = false;
          this.tableData = [];
          this.saveLoading = false;
          this.tableLoading = false
          this.$loading().close();
        },
        (error) => {
          this.saveLoading = false;
          this.tableLoading = false
        }
      )
        .finally(() => {
          this.tableOnLoad(this.page);
        })
        .catch(() => {
          this.saveLoading = false;
          this.tableLoading = false
          this.$loading().close();
          // this.$refs.formLayout.$refs.form.allDisabled = false
        });
      // })
      this.$loading().close();
    },
    headRemove() {
      let selectionList = this.$refs.gridLayTableTopic.selectionList
      let selectionQuestionIdList = []
      let ids = []
      if (selectionList.length !== 0) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          selectionList.forEach((e) => {
            if (e.id) {
              ids.push(e.id)
            }
            selectionQuestionIdList.push(e.questionId)
          })
          removeByEduPaperLine(ids.toString())
            .then(res => {
              this.tableOnLoad(this.page);
              this.$message({
                type: "success",
                message: "操作成功!"
              });
            })
          this.page.total = this.tableTopicData.length
          this.tableTopicData = this.tableTopicData.filter(e => !selectionQuestionIdList.includes(e.questionId));
          this.selectTopicCodes = this.selectTopicCodes.filter(e => !selectionQuestionIdList.includes(e));
        })
      } else {
        this.$message({
          type: "warning",
          message: "请至少选择一条数据!",
        });
      }
    },
    headAdd() {
      this.$refs.gridLayOut.getGrid().rowCellAdd({
        topicType: "",
        subjectType: "",
        facilityType: "",
        number: "1",
        score: "",
      })
      // this.page.total = this.tableData.length + 1;
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].$cellEdit = true;
      }
    },
    calculate(row) {
      if (row && row.value !== null && row.value !== '') {
        if (row.value.toString().includes('-')) {
          this.tableTopicData.forEach((e) => {
            if (e.id === row.row.id) {
              e.score = -e.score;
            }
          })
        }
      }

      let account = 0;
      for (let i = 0; i < this.tableTopicData.length; i++) {
        let score = Number(this.tableTopicData[i].score)
        if (score.toString() === 'NaN') {
          score = 0;
        }
        account = Number(account) + score;
      }
      this.$refs.formLayout.dataForm.totalScore = account;
    },
    tableCalculate(row) {
      if (row.value.toString().includes('-')) {
        this.tableTopicData.forEach((e) => {
          if (e.id === row.row.id) {
            e.score = -e.score;
          }
        })
      }
      let account = 0;
      for (let i = 0; i < this.tableTopicData.length; i++) {
        let score = Number(this.tableTopicData[i].score)
        if (score.toString() === 'NaN') {
          score = 0;
        }
        account = Number(account) + score;
      }
      this.$refs.formLayout.dataForm.totalScore = account;
    },
    rowDelete(index) {
      this.tableData.splice(index, 1);
    },
    rowRemove(row, index) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (row.id) {
            removeByEduPaperLine(row.id)
              .then(res => {
                this.tableTopicData = this.tableTopicData.filter(e => !row.questionId.includes(e.questionId));
                this.selectTopicCodes = this.selectTopicCodes.filter(e => !row.questionId.includes(e));
                this.tableOnLoad(this.page);
                this.$message({
                  type: "success",
                  message: "操作成功!"
                });
              });
          } else {
            this.rowDelete(index);
            this.$refs.gridLayOut.selectionClear()
          }
          // this.tableTopicData = this.tableTopicData.filter(e => !row.questionId.includes(e.questionId));
          // this.selectTopicCodes = this.selectTopicCodes.filter(e => !row.questionId.includes(e));
        })
    },
    formData(id) {
      getDetail(id).then((res) => {
        this.$refs.formLayout.dataForm = res.data.data;
      });
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      getLineList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        // this.page.total = data.total;
        this.tableData = data.records;
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].$cellEdit = true;
        }
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    handleSizeChange(val) {
      this.page.pageSize = val

      this.pageData = this.queryByPage()
    },
    // 改变当前页的回调
    handleCurrentChange(val) {
      this.page.currentPage = val

      this.pageData = this.queryByPage()
    },
    queryByPage() {
      // 起始位置 = (当前页 - 1) x 每页的大小
      const start = (this.page.currentPage - 1) * this.page.pageSize
      // 结束位置 = 当前页 x 每页的大小
      const end = this.page.currentPage * this.page.pageSize

      return this.tableTopicData.slice(start, end)
    },
    tableOnLoad(params = {}) {
      this.tableLoading = true;
      if (this.dataForm.id) {
        params = {paperId: this.dataForm.id}
      }
      topicPage(
        1,
        500,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableTopicData = data.records;
        this.tableTopicData.forEach((e) => {
          this.selectTopicCodes.push(e.questionId);
          e.$cellEdit = true;
        })
        this.pageData = this.queryByPage()
        this.tableLoading = false;
        this.groupStatistics();
        // this.tableTopicData.selectionClear();
      });
    },
    topicOnLoad(page, params = {}) {
      this.paperPage = page;
      this.tableLoading = true;
      params.subjectType = this.subjectType
      getTopicList(page.currentPage, page.pageSize, Object.assign(params)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.topicData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
  },
  mounted() {
    this.initData()
    listAll().then(res => {
      screenData = res.data.data;

    });
  },
  created() {
    this.getdictionaryBiz();
    if (this.$route.query.row) {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.id = row.id;
      this.groupStatistics();
    }
    this.type = this.$route.query.type;
    this.testPaperType = this.$route.query.testPaperType;
    if (this.type == "add") {
      this.dataForm.id = "";
      this.dataForm.totalScore = 0;
    } else if (this.type == "edit") {
      this.query.paperId = this.dataForm.id;
      this.formData(this.dataForm.id);
      // this.onLoad(this.page)
      this.tableOnLoad(this.page)
    } else if (this.type == "view") {
      this.query.paperId = this.dataForm.id;
      this.formData(this.dataForm.id);
      // this.onLoad(this.page)
      this.tableOnLoad(this.page)
    }
  }
}
</script>

<style lang="scss" scoped>
.selectionListTips{
  padding: 0 0 10px 12px;
}
::v-deep .avue-crud .el-table {
  height: calc(100vh - 538px) !important;
  max-height: calc(100vh - 538px) !important;
}

::v-deep .el-checkbox {
  margin-right: 5px !important;
}

.paperDialog ::v-deep .el-dialog__body {
  padding: 10px;
}

.knowledge ::v-deep .el-dialog__body {
  padding-top: 20px !important;
}

//.paperDialog ::v-deep .el-table__fixed-header-wrapper .el-checkbox__inner {
//  display: none !important;
//}
//
//.knowledge ::v-deep .el-table__fixed-header-wrapper .el-checkbox__inner {
//  display: block !important;
//}

.paperDialog ::v-deep .el-tree {
  height: calc(100vh - 348px) !important;
}

.paperDialog ::v-deep .el-table {
  height: calc(100vh - 400px) !important;
  max-height: calc(100vh - 400px) !important;
}

.bottomPagination {
  background-color: #FFFFFF;
  padding: 0 12px 10px 12px;
  display: flex;
  justify-content: flex-end;
}
.questionStaics{
  padding: 10px 0 10px 4px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  .q-item{
    margin-right: 10px;
    display: flex;
    align-items: center;
    .q-label{
    }
    .q-value{

    }
  }
}
</style>
